import 'twin.macro'

import * as React from 'react'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import Layout from '../components/Layouts/default'
import { Container } from 'semantic-ui-react'

import InnerImageZoom from 'react-inner-image-zoom'

import imgMenuBSN from '../assets/images/menu/menu-keo-nougat-si-le.jpg'

export default function BanhKeoTetPage() {
  return (
    <Layout>
      <TailwindLayout>
        <Container>
          <h1 tw="h-1 pt-5 pb-10">Menu Kẹo Nougat</h1>
          <InnerImageZoom
            src={imgMenuBSN}
            zoomSrc={imgMenuBSN}
            alt="Menu Kẹo Nougat"
          />
        </Container>
      </TailwindLayout>
    </Layout>
  )
}
