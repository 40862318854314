import React from 'react'
import TailwindStyles from '../../styles/TailwindStyles'
import ToastContainer from '../ToastContainer'
const TailwindLayout = ({ children, ...rest }) => (
  <div {...rest}>
    <TailwindStyles />
    <ToastContainer />
    {children}
  </div>
)

export default TailwindLayout
